@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&display=swap');

//CSS for form input mixin start 
@mixin forms {
  border: 0;
  border-bottom: 1.5px solid gray;
  margin-bottom: 40px;
  width: 100%;
  padding: .8rem 0;
  transition: all .15s ease-in-out;

  &::placeholder {
    color: transparent;
  }

  &:focus,
  &:not(:placeholder-shown) {
    outline: none;
    border-color: #fb8627;
    border-width: 2px;
    font-size: 17px;
    padding-top: 1.5rem !important;

    &~.form-input-label {
      top: -25px;
      font-size: 15px;
      color: var(--text-cc-dark);
    }
  }
}

// CSS for form input mixin stop

//CSS for Form Input (Super reusable)
.signInForm {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.group {
  position: relative;
  width: 100%;
}

.group .form-input {
  background: transparent;
  border: 0;
  border-bottom: 1.5px solid gray;
  margin-bottom: 40px;
  padding: 0.5rem 0;
  transition: all .15s ease-in-out;
  width: 100%;
}

.group .form-input-label {
  color: var(--text-cc-dark);
  font-size: 17px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .15s ease-in-out;
}

.form-input {
  @include forms
}

// End of Form Input CSS

:root {
  --text-cc-dark: #172336;
  --text-cc: #fb8627;
}

html {
  font-size: 13px;
}

@media only screen and (min-width: 767px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 922px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: "Nunito Sans", sans-serif;
  color: #222335;
}

//Padding CSS start
.pt-70 {
  padding-top: 70px;
}

.pt-50 {
  padding-top: 50px;
}

//Padding CSS end

//Font-sizes CSS start
.font-30 {
  font-size: 30px !important;
  line-height: 1.1;
}

.font-28 {
  font-size: 28px !important;
}

.font-22 {
  font-size: 22px !important;
}


//Font-sizes CSS end

.navigation {
  height: 99px;
  width: 100%;
  z-index: 100;
  position: relative;
  top: 0;
  left: 0;

  &__inner {
    width: 100%;
  }

  &__link {
    margin-left: 2.5rem;
    text-decoration: none !important;
    color: #222335;
    font-size: 1rem;
    font-weight: 500;

    &:hover {
      color: #222335;
    }
  }
}

p {
  font-weight: 300;
}

.hero {
  padding: 8rem 0 5rem 0;
  z-index: 0;

  &__title {
    font-size: 4.2rem;
    font-weight: 900;
  }

  &__bg {
    content: "";
    height: 90vh;
    width: 50%;
    background: #f7f6fc;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
  }
}

.btn {
  border-radius: 6px;
  padding: .5rem 1rem;

  &-primary {
    background: #fb8627 !important;
    border: #fb8627 !important;
    color: #fff !important;

    &:not(:disabled):hover {
      background: transparent !important;
      border: 1px solid #fb8627 !important;
      color: #fb8627 !important;
    }

    &:not(:disabled):active,
    &:not(:disabled):focus {
      color: #fff;
      background-color: #172336 !important;
      border-color: #172336 !important;
    }
  }

  &-white {
    background: #fff;
    color: var(--text-cc-dark);

    &:hover {
      background: transparent;
      border: 1px solid #fff;
      color: #fff;
    }
  }

}

.btn.cta {
  padding: .8rem 2rem;
}

.card {
  &__body {
    padding: 2rem 1.7rem;
    border: 1px solid #eee;
    box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08),
      0px 24px 32px rgba(50, 50, 71, 0.08);
    background: #fff;

    &__round {
      border-radius: 2rem 8px 8px 8px;
    }
  }

  &__icon-container {
    height: 55px;
    width: 55px;
    background: #b5f2e3;
    border-radius: 7px;
  }
}

section {
  padding: 6rem 0;
}

.section {
  &__robo {
    left: 0;
    bottom: 0;
  }

  &__more-space {
    padding-top: 14rem;
  }

  &__colored {
    background: #f8f7ff;
  }

  &__grad {
    background: linear-gradient(180deg,
        #ece8ff 0%,
        rgba(236, 232, 255, 0) 100%);
  }
}

.title {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;

  &--small {
    font-size: 1.3rem;
  }

  &--bigger {
    font-size: 3rem;
    font-weight: 300;
  }

  &__wrapper {
    min-width: 100%;
    transform: translateY(0rem);
    margin-top: 5rem;

    &__pattern {
      position: absolute;
      right: 0;
      top: -5.5rem;
      z-index: 0;
    }
  }
}

.sub {
  color: #37b5ff;
  font-size: 0.9rem;
}

.container {
  z-index: 2;
}

.navigation__logo {
  width: 100px;
}

.dot-pattern {
  max-width: 180px;
  bottom: 40%;
  left: -10%;
}

.count__card {
  padding: 1.5rem;
  height: 100%;
}

.custom-round {
  border-radius: 7px;
}

.strip {
  background: #002474;
  padding: 3.5rem 3rem;
  transform: translateY(50%);
  box-shadow: 0px 20px 20px rgba(50, 50, 71, 0.1),
    0px 32px 40px rgba(50, 50, 71, 0.1);
  border-radius: 19px;
  margin-bottom: 27%;
}

.footer {
  padding: 10rem 0 3rem;

  &__link {
    margin-left: 2rem;
    display: inline-block;
    color: #032752;

    &:hover {
      text-decoration: none;
      color: #e07929;
    }
  }
}

@media only screen and (min-width: 767px) {
  .strip {
    margin-bottom: 9%;
  }

  .title__wrapper {
    min-width: 50%;
    transform: translateY(3rem);
    margin-top: 0;
  }
}

.bg-purple {
  background: #c1aef8;
}

.bg-blue {
  background: #b5f2e3;
}

.bg-pink-dark {
  background: #f8aec3;
}

.bg-pink {
  background: #facfca;
}

.count-strip {
  background: linear-gradient(rgba(54, 62, 76, 0.7), rgba(55, 57, 81, 0.7)), url('../public/assets/images/went-shopping-today-shot-attractive-african-woman-sitting-car-salon.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 3rem;
  border-radius: 2rem;
  transform: translateY(50%);
}

.mt-6 {
  margin-top: 6rem;
}

.pl-6 {
  padding-left: 6rem
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 20rem 0 3rem 0;
  }
}

.custom-bg {
  background: url('../public/assets/images/eat-bg.png');
  background-size: cover;
}

.text h1 {
  font-size: 4rem;
  font-weight: 900;
}

.text-cc {
  color: #e07929 !important;
}

.text-cc-dark {
  color: #172336 !important;
}

.navigation__links a:hover {
  color: #e07929;
  font-weight: 800;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.dotted-bg {
  background: url('../public/assets/images/footer_bg.webp');
}

.title-small {
  font-size: 3.3rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.3rem;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.card {
  min-height: 300px;
  background: #3a2d42;
  transition: all ease 500ms;

  &:hover {
    transform: scale(1.1);

    .number-icon {
      left: 35%;
    }
  }
}

.number-icon {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  text-align: center;
  line-height: 41px;
  font-size: 15px;
  margin-bottom: 30px;
  background: #000;
  color: #fff;
  font-weight: 700;
  transition: all ease 500ms;

  &::before {
    content: "";
    width: 47px;
    border-radius: 50%;
    height: 47px;
    position: absolute;
    left: -4px;
    top: -4px;
    opacity: 0;
    border-left: 2px solid rgb(255, 165, 0);
  }
}

.icon-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  min-height: 120px;
  transition: all 500ms ease;
  color: #ffa500;
}

.chop-life-img {
  height: 100%;
  object-fit: cover;
  border-bottom: 24px;
}

.bg-green {
  background: #243423;
}

.bg-maroon {
  background: #381b1b;
}

.bg-dark-blue {
  background: #1b3238;
}

.achievement {
  padding: 1rem 3rem;
  background: #F3EFE6;
}

.walk {
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  line-height: 1.4;
  color: #222;
  letter-spacing: 0.5rem;
  position: relative;
  font-size: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 5.5rem;
    left: 4rem;
    width: 5rem;
    height: 3px;
    border-radius: 10rem;
    background: #fb8627;
  }

  &::after {
    content: "";
    position: absolute;
    top: 5.3rem;
    left: 4rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #032752;
    animation: 7s linear 0s infinite normal none running walk;
  }
}

@keyframes walk {
  0% {
    left: 4rem;
  }

  50% {
    left: 8.5rem;
  }

  100% {
    left: 4rem;
  }
}

.arrow {
  position: absolute;
  left: 30rem;
  top: 13rem;

  img {
    height: 115px;
  }
}

.punctuate {
  font-size: 14px;
}

.text-black {
  color: #222;
}

.fa-solid,
.fas {
  font-family: "Font Awesome 6 Free", sans-serif !important;
  font-weight: 900;
}

.fa-brands {
  font-family: "Font Awesome 6 Brands" !important;
  font-weight: 900;
}

.wrapper {
  // display: flex;
  width: 100%;
  align-items: stretch;
  position: relative;
}

.sidebar {
  min-width: 550px;
  max-width: 550px;
  background-color: #fff;
  background-size: contain;
  color: #fff;
  transition: all 0.3s;
  height: 100%;
  width: 350px;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 2rem 2.5rem;
  margin-right: -550px;
}

.sidebar.active {
  margin-right: 0;
}

#main {
  transition: all 0.3s;
  width: 100%;
  position: relative;
  // margin-right: 0;
}

.main-overlay {
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  height: 100%;
  width: 100%;
  position: fixed;
  transition: all .3s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

.redirect-text {
  color: #000;
  line-height: 1.1;
  font-size: 1.5rem;
}


#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .card1 {
    min-height: 330px !important;
  }

  .sidebar {
    margin-right: -800px;
    min-width: 100%;
  }

  .sidebar.active {
    margin-left: 0;
  }

  .redirect-text {
    color: #000;
    line-height: 2.2rem;
    font-size: 2rem;
  }

}

.close {
  opacity: 1;
}

.x {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 1rem;
  border: 1px solid #c2334d;
  color: #c2334d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  transition: all .2s ease-in-out;
  background: none;
  position: relative;
  z-index: 2;
  position: absolute;
  right: 0;

  &:hover {
    color: #fff;
    background: #c2334d;
  }
}

.back {
  width: 35px;
  position: absolute;
  top: 2rem;
  height: 35px;
  border-radius: 50%;
  font-size: 1rem;
  border: 1px solid var(--text-cc-dark);
  color: var(--text-cc-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  background: none;

  &:hover {
    color: #fff;
    background: var(--text-cc-dark);
  }
}

.block {
  display: block;
}

.btn-cc {
  background: #fb8627;
  color: #fff;

  &:hover {
    color: #fb8627;
    border: 1px solid #fb8627;
    background: transparent;
  }
}

.view-more {
  color: #fff !important;
  border: 1px solid #fff;
  background: transparent;
  opacity: 0.8;
}

.arrow-two {
  position: absolute;
  right: 22rem;
  bottom: 12rem;

  img {
    height: 145px;
  }
}

.hero-two {
  padding: 25vh 10%;
  position: relative;
  min-height: 100vh;
  transform-style: inherit;
  overflow: hidden;
  clip-path: polygon(0px 7%, 100% 0px, 100% 100%, 0px 100%);
  margin-top: -90px;
  z-index: 10;
  background: url('../public/assets/images/ser-bg1.png');
  background-attachment: fixed;
  background-size: 100%;
}

.snapshot {
  display: flex;
  align-items: center;
  font-size: 22px;

  li {
    position: relative;
    margin: 0 auto;
    list-style: none;
    padding: 0px;
    margin: 0px;

    &::before {
      content: "";
      height: 30px;
      width: 30px;
      display: inline-block;
      position: absolute;
      left: -30px;
      // top: 8px;
      margin-right: 22px;
      vertical-align: text-top;
      background: url(../public/assets/images/bullseye.svg) no-repeat 50%;
      background-size: contain;
      padding-top: 5px;
    }
  }
}

.area {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #b3a3a3;
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 80px;
  height: 80px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 100px;
  height: 100px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 100px;
  height: 100px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 60%;
  }

}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px darken(#d48bb2, 8%);
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;

  &:nth-child(1) {
    border: 1px solid #012B88;
  }

  &:nth-child(2n) {
    border-color: lighten(rgb(251, 134, 39), 10%);
  }

  &:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
  }

  &:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
  }

  &:nth-child(4) {
    animation-delay: 2s;
    left: 90vw;
    top: 10vh;
    border-color: #012B88;
  }

  &:nth-child(5) {
    animation-delay: 4s;
    left: 10vw;
    top: 85vh;
  }

  &:nth-child(6) {
    animation-delay: 2s;
    left: 50vw;
    top: 10vh;
  }

  &:nth-child(7) {
    animation-delay: 4s;
    left: 5vw;
    top: 25vh;
  }

  &:nth-child(8) {
    animation-delay: 6s;
    left: 90vw;
    top: 90vh;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }

  to {
    transform: scale(8) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.responsive-btn {
  display: none;
}

@media only screen and (max-width: 767px) {
  .responsive-btn {
    display: block;
  }

  .hero-two {
    background-size: cover;
  }

  .polkadot-bg {
    background-size: cover;
  }

  .arrow-two img {
    display: none;
  }
}

.pt-70 {
  padding-top: 70px;
}

.pt-100 {
  padding-top: 100px;
}


.font-40 {
  font-size: 40px;
}

blockquote::before {
  font-family: Arial;
  content: "“";
  color: rgb(251, 134, 39);
  ;
  font-size: 7em;
  position: absolute;
  left: 0rem;
  top: 5rem;
}

.lh-30 {
  line-height: 30px;
}

//Illustration image CSS start 
.illustration-image {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 80%;
  z-index: -1;
  bottom: 0rem;
  right: 0rem;
  // display: none;

  img {
    width: 70%;
  }
}

.font-20 {
  font-size: 20px !important;
}

// Illustration image CSS end
@media only screen and (max-width:478px) {
  .summary-card {
    min-height: 300px !important;
  }
}

@media only screen and (max-width:394px) {
  .count-strip {
    .title {
      text-align: left !important;
    }
  }

  .summary-card {
    min-height: 310px !important;
  }
}

// Picture upload CSS start 
.picture-upload {
  height: 200px;
  width: 200px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 50%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  span {
    font-size: 50px;
    color: var(--text-cc-dark);
  }
}

// Picture upload CSS end
//Add card CSS start 
.fill-width {
  width: 100% !important;
}

.card-paystack {
  width: 95% !important;
  background-color: #1e3a8a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  padding-top: 3rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  transition: all .3s ease-in-out;
  color: #fff !important;
}

.btn-paystack {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  border: 0;
  background: none;
}

//Swiper slides CSS start
.bg-pink-two {
  background: #ffeee0 !important;
}

.food-card {
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;
  min-height: 400px;
  z-index: 0;
  cursor: pointer;
  transition: 1s ease-in-out;
}

.services-icon {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.feature-box-rounded {
  border-radius: 50px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 8px;
  padding: 2rem;
}

.meal-plans {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3rem 1.5rem;
}

.swiper-slide {
  margin-right: 15px !important;
}

// Swiper slides CSS end

//Spinner CSS start
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: relative;
}

.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

// Spinner CSS end

// Pin input CSS start
.w-16 {
  width: 4rem
}

.h-16 {
  height: 4rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.bg-gray-900 {
  background-color: #111827;
}

.bg-gray-800 {
  background-color: #1F2937;
}

.bg-gray {
  background-color: #ededed;
}

.bg-opacity-5 {
  opacity: 0.5;
}

.bg-opacity-20 {
  opacity: 0.2;
}

.w-2 {
  width: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.h-2 {
  height: 0.5rem;
}

.bg-cc {
  background-color: var(--text-cc);
}

.bg-cc-dark {
  background-color: var(--text-cc-dark);
}

.bg-opacity-10 {
  opacity: 0.1;
}

.bg-opacity-100 {
  opacity: 1;
}

.mt-12 {
  margin-top: 3rem;
}

.grid {
  display: grid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-4 {
  margin-left: 1rem;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

//Pin input CSS end

//Menu animation CSS start 
.inner-background {
  position: absolute;
  inset: 0;
  cursor: pointer;
  background-size: cover;
}

.food-card {
  &:hover {
    .inner-background {
      transform: scale(1.2);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    .content {
      opacity: 0.9;
    }

    .view-more {
      opacity: 1;
      transform: translateX(0px);
      transition: ease-in .5s;
    }
  }
}

//Menu animation CSS end
.pointer {
  cursor: pointer;
}

//Spinner CSS start 
.spin {
  display: block !important;
  animation: spin 2s ease infinite alternate;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//Spinner CSS stop
.fetch-restaurant-gif {
  height: 250px;
  width: 250px;
  // margin: 0 auto;

  img {
    width: 100%;
  }
}

.balance-section {
  background: var(--text-cc-dark);
  border-radius: 12px;
  padding: 1rem
}

.summary-card {
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;
  min-height: 350px;
  z-index: 0;
  cursor: pointer;
  transition: 1s ease-in-out;
  background: #000;
  // border: 1px solid #e2e2e2;
  box-shadow: -2px -1px 7px 5px rgba(0, 0, 0, 0.05);

  .icon {
    border-radius: 24px;
    position: absolute;
    background: #0c3d8c;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    height: 70px;
    right: -100px;
    line-height: 41px;
    margin-bottom: 30px;
    text-align: center;
    top: -15px;
    transition: all .5s ease;
    width: 80px;
  }

  &:hover {
    .icon {
      right: -20px;
    }
  }
}

.tweet-skeleton {
  opacity: 0.8;
}

.call-number {
  font-size: 18px;
}

.cropper {
  position: absolute;
  bottom: 0;
  padding: 6rem;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  right: 0rem;
  justify-content: center;
  top: 0;
  align-items: center;
}

.summary-menu {
  margin-top: 5rem;
  text-align: right;
}

.bob {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 1rem;
  left: 0;
}

.pyramid {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 2rem;
  right: 5rem;
}

.curved-square {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 7rem;
  left: 6rem;
}

.double-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 6rem;
  left: 0rem;
}

.cross {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 10rem;
  left: 3rem;
}

.triangle {
  position: absolute;
  width: 40px;
  height: 45px;
  top: 15rem;
  left: 7em;
  transform: rotate(85deg);
}

.bg-ride {
  background: url('../public/assets/images/bg-ride.png');
  background-size: 100%;
  min-height: 800px;
  background-repeat: no-repeat;
}

.ride-img {
  width: 150px;
  position: absolute;
  right: -4rem;
  bottom: -1rem;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .home-img {
    width: 100% !important;
  }

  .md-pt-70 {
    padding-top: 70px !important;
  }

  .bg-ride {
    background: url('../public/assets/images/footer_bg.webp');
    min-height: 600px;

  }

  //   .ride-img {
  //     width: 200px;
  //     position: absolute;
  //     right: -2.5rem;
  //     bottom: 3rem;
  // }
}

.services-wrap {
  background-image: linear-gradient(137deg, #FFFFFF 0%, #FFFFFF73 100%);
}

.services-item {
  margin: 0 8px 0 8px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  border-radius: 30px 30px 30px 30px;
  box-shadow: 38px 45px 93px -10px rgba(0, 0, 0, 0.05);
}

.services-wrap {
  text-align: center;
  padding: 60px 60px 55px 60px;
  border-radius: 30px 30px 30px 30px;
  /* overflow: hidden; */
  background-color: transparent;
  background-image: linear-gradient(137deg, #ffffff 0%, #ffffff 73 100%);
}

.services-img img {
  height: 110px;
  width: 110px;
  max-width: unset;
}

.services-img img {
  height: 50px;
  width: auto;
}

.services-content .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  margin: 0 0 21px;
  transform-origin: bottom;
  transition: 0.5s;
}

.services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3E3E3E;
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  transform-origin: top;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(20px) scaleY(0);
  transform-style: preserve-3d;
}

.services-content .services-txt p {
  background: #172336;
  width: 70%;
  padding: 1.2rem;
  margin: 0;
  color: #fff;
  border-radius: 12px;
}

.services-item:hover .services-txt {
  transform: translateZ(20px) scaleY(1) !important;
}

.services-item:hover .services-txt p {
  box-shadow: -1px 5px 20px 5px rgba(95, 63, 176, 0.2);
}

.ride-logo-container {
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
}

.details {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  transform-origin: top;
  transition: 0.5s;
  transform: translateZ(20px) scaleY(1) !important;
  transform-style: preserve-3d;
  background: #172336;
  padding: 1.2rem;
  margin: 0;
  color: #fff;
  border-radius: 12px;
}

.ride-btn {
  background: #fb8627;
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &:hover {
    background-color: #04AA6D;
  }
}

.checked-bg {
  height: 25px;
  background: lightgreen;
  border-radius: 50%;
  width: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  left: 1.5rem;
}

.sub-text {
  font-size: 15px;
  position: absolute;
  top: 0.3rem;
}

.ride-title {
  font-size: 2.2rem;
}

.font-15 {
  font-size: 15px !important;
}

.ride-service-disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: none;
}

.options-card {
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 0;
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05)
}

.text-deep-blue {
  color: #0a0a5e !important;
}

.bg-black {
  background-color: #000 !important;
}

.error-text {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border-radius: 8px;
}

.btn-grey {
  background-color: #e9e9e9;
}

.btn-outline-gray {
  border: 1px solid gray;
  color: gray;

  &:hover {
    background: gray !important;
    color: #fff !important;
  }
}

.btn-blue {
  background: transparent !important;
  border: 1px solid #0c3d8c !important;
  color: #0c3d8c !important;

  &:hover {
    background: #0c3d8c !important;
    color: #fff !important;
  }
}

.text-grey {
  color: grey !important;
}

#first {
  display: block;
  width: 340px;
  height: 70px;
  border: thin solid red;
  background-color: #cfcfcf;

}

#popup {
  display: block;
  width: 340px;
  height: 70px;
  border: thin solid black;
  background-color: lightcoral;
  border-radius: 10px;
  text-align: center;
  padding: 15px;

}

.ride-contact {
  min-width: 450px;
  max-width: 450px;
  background-color: #fff;
  background-size: contain;
  color: #fff;
  animation: slideleft 0.3s ease-in-out;
  height: 100%;
  width: 350px;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 2rem 2.5rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

@keyframes slideleft {
  from {
    margin-right: -450px;
  }

  to {
    margin-right: 0px;
  }
}

.success-bolt-img {
  width: 200px;
}

.old-amount {
  text-decoration: line-through;
  opacity: .5;
}

@media only screen and(max-width: 768px) {
  .ride-contact {
    min-width: 80%;
    max-width: 80%;
  }

  .success-bolt-img {
    width: 130px
  }

}

.alt-plan {
  text-decoration: underline;
  background: transparent !important;
  border: none !important;
  color: #a1bae9 !important;
}

.btn-blue-full {
  background: #0c3d8c;
  border: 1px solid #0c3d8c !important;
  color: #fff !important;
}

.opacity-2 {
  opacity: 0.5;
}

.ribbon {
  background: url('../public/assets/images/ribbon.svg');
  background-size: 100%;
  bottom: 2rem;
  right: 0.5rem;
  position: absolute;
  height: 120px;
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #000;
    margin-top: -1rem;
    font-weight: bold;
  }
}

.active-ribbon {
  background: url('../public/assets/images/ribbon.svg');
  background-size: 100%;
  margin-left: auto;
  right: 0.5rem;
  height: 120px;
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #000;
    margin-top: -1rem;
    font-weight: bold;
    font-size: 12px;
  }
}

.family {
  background: linear-gradient(rgba(49, 31, 60, .6), rgba(78, 57, 96, .6)), url('../public/assets/images/unity.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position-y: 50%;
  position: relative;
}

.sec-title {
  text-align: center;
  font-size: 30px;
  padding: 160px 0;
  color: #fff;
  padding: 160px 0;
}

.about-animation {
  .animate {
    position: absolute;
    bottom: 0;
}
  .animate img {
    max-width: 20px;
  }

  .animate.one {
    left: 45%;
    top: 17%;
  }

  .animate.two {
    right: 14%;
    top: 10%;
  }

  .animate.three{
    left: 47%;
    bottom: 20%;
  }
}